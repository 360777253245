import React from "react";
import cn from "clsx";
import Loading from "../Loading";
import "./LoadingScreen.css";

export interface LoadingScreenProps {
  /** The loading message */
  message?: string;
  /** Extra content to render underneath the loading indicator. */
  children?: React.ReactNode | React.ReactNodeArray;
  /** Any extra class names. */
  className?: string;
}

export default function LoadingScreen({
  message = "",
  children,
  className
}: LoadingScreenProps) {
  return (
    <div className={cn("loading-screen", className)}>
      <Loading message={message} />
      {children}
    </div>
  );
}
