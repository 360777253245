import React from "react";
import cn from "clsx";
import { DesignSize, DesignTheme } from "../../types";
import "./Icon.css";

export type IconSize = DesignSize;
export type IconShape = "default" | "outline";
export type IconTheme = DesignTheme;

export interface IconProps {
  /**
   * If a name is provided, it will assign a `icon-name-{name}` class, where
   * you can then utilise pre-defined CSS classes to render icons.
   *
   * @default ""
   */
  name?: string;
  /**
   * The size keyword affects the icon's CSS (`icon-size-{size}`).
   *
   * @default "m"
   */
  size?: IconSize;
  /**
   * The shape is another CSS class moniker (`icon-shape-{shape}`).
   */
  shape?: IconShape;
  /**
   * The theme is another CSS class moniker (`icon-theme-{theme}`).
   */
  theme?: IconTheme;
  /**
   * It's possible to provide a custom icon element (i.e. an SVG or image)
   * to display the icon itself.
   *
   * If you use `currentColor` within the SVG (e.g. for fills or outlines),
   * then it will pick up the cascaded `color` CSS property value.
   */
  children?: React.ReactNode;
  /**
   * Extra class names to give the icon element.
   */
  className?: string;
}

export default function Icon({
  name = "",
  size = "m",
  shape,
  theme,
  children,
  className
}: IconProps) {
  return (
    <span
      className={cn(
        "icon",
        className,
        !!name && `icon-name-${name}`,
        !!size && `icon-size-${size}`,
        !!shape && `icon-shape-${shape}`,
        !!theme && `icon-theme-${theme}`
      )}
    >
      {!!children ? children : null}
    </span>
  );
}
