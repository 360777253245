import React from "react";
import cn from "clsx";
import Icon from "../Icon";

export interface LoadingProps {
  message?: string;
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
}

export default function Loading({
  message = "",
  className,
  children
}: LoadingProps) {
  return (
    <div className={cn("loading", className)}>
      <span className="loading-indicator">
        <Icon name="loading" />
      </span>
      {!!message && <span className="loading-message">{message}</span>}
      {children}
    </div>
  );
}
