// See : <https://medium.com/@FezVrasta/service-worker-updates-and-error-handling-with-react-1a3730800e6a>
import _ from "lodash";
import React, { ReactNode } from "react";
import { createContext, useState, useEffect } from "react";
import * as serviceWorker from "../../utils/serviceWorker";

export interface IServiceWorkerContext {
  isUpdateAvailable: boolean;
  updateAssets(): void;
}

export const ServiceWorkerContext = createContext<IServiceWorkerContext>({
  isUpdateAvailable: false,
  updateAssets: () => {}
});

export default function ServiceWorkerProvider({
  children
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) {
  const [
    waitingServiceWorker,
    setWaitingServiceWorker
  ] = useState<ServiceWorker | null>();
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: registration => {
        if (registration) {
          setWaitingServiceWorker(registration.waiting);
          setUpdateAvailable(true);
        }
      },
      onWaiting: waiting => {
        setWaitingServiceWorker(waiting);
        setUpdateAvailable(true);
      }
    });
  }, []);

  useEffect(() => {
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated.
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (
          event &&
          event.target &&
          _.get(event.target, "state") === "activated"
        ) {
          console.log(
            "Assets are being updated by reloading the window at",
            new Date()
          );
          window.location.reload();
        }
      });
    }
  }, [waitingServiceWorker]);

  const value: IServiceWorkerContext = React.useMemo(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        if (waitingServiceWorker) {
          // We send the SKIP_WAITING message to tell the Service Worker
          // to update its cache and flush the old one
          waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
      }
    }),
    [isUpdateAvailable, waitingServiceWorker]
  );
  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
}
