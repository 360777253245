import "normalize.css/normalize.css";
import React from "react";
import ReactDOM from "react-dom";
import { AppConfig } from "./types";
import AppLoading from "./components/AppLoading";
import "./styles";
import ServiceWorkerProvider from "./containers/ServiceWorkerProvider";

// Basic components to enable chunking the app and showing
// that chunked parts are loading.
const AppWrapper = ({
  children
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) => <React.Suspense fallback={<AppLoading />}>{children}</React.Suspense>;

const AppAuth = React.lazy(() =>
  import(
    /* webpackChunkName: "app-auth" */
    "./containers/AppAuth/index"
  )
);

const App = React.lazy(() =>
  import(
    /* webpackChunkName: "app" */
    "./containers/App/index"
  )
);

async function loadApp() {
  // Need to load the config first...
  const config: AppConfig = await import(
    /* webpackChunkName: "config" */
    "./config"
  ).then(module => module.default);

  // Then perform the bootstrapping in parallel with...
  import(
    /* webpackChunkName: "bootstrap" */
    "./bootstrap"
  );

  // This will load the brand's theme (styles, fonts, images, icons, etc.)
  const AppThemeLoader = ({ id = "lvmh" }: { id?: string }) => {
    const AppTheme = React.lazy(() =>
      import(
        /* webpackChunkName: "theme" */
        `./config/${id}/Theme.tsx`
      )
    );
    return <AppTheme />;
  };

  // Then initialise the app to start loading...
  const appRoot: HTMLElement = document.getElementById("root") as HTMLElement;
  ReactDOM.render(
    <ServiceWorkerProvider>
      <AppWrapper>
        <AppAuth>
          <App />
        </AppAuth>
        {/* Needs to be after App and AppAuth so CSS can be loaded last for higher precedence */}
        <AppThemeLoader id={config.id} />
      </AppWrapper>
    </ServiceWorkerProvider>,
    appRoot
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();

loadApp();
